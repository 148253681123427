import {
  Card,
  Typography,
  Stack,
  Box,
  Avatar,
  CardHeader,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import ListLoading from "../../Loading/ListLoading";
import { useOrderDetailsStore } from "../../../../store/stockistPageStore/orderStore/orderDetailsStore";
import IMAGES from "../../../../assets/images";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetailsModal from "../../modals/OrderDetailsModal";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const Orders = () => {
  const {
    fetchPatientOrders,
    patientOrderList,
    handleGetOrderDetails,
    fetchPatientOrderState,
  } = useOrderDetailsStore();
  const { selectedCard, isBlurred } = useEnrollmentMainStore();
  const [openOrderDetailsModal, setOpenOrderDetailsModal] =
    useState<boolean>(false);

  useEffect(() => {
    fetchPatientOrders(1, {}, selectedCard.patient_uuid, false);
  }, []);

  const handleViewOrderDetails = (id: number) => {
    handleGetOrderDetails(id.toString());
    setOpenOrderDetailsModal(true);
  };

  const handleCloseOrderDetails = () => {
    setOpenOrderDetailsModal(false);
  };

  return (
    <>
      <Box maxHeight={"66vh"} overflow="scroll" width={"110%"}>
        <Stack
          position={"fixed"}
          bgcolor={"white"}
          zIndex={10}
          paddingBottom={2}
          width={"44%"}
          alignItems={"start"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle2" fontWeight={660} color={"secondary"}>
            Total Orders : {isBlurred ? "-" : patientOrderList.length}
          </Typography>
          <TextField
            // onChange={debouncedOnChange}
            size={"small"}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            placeholder="Search by order id..."
          />
        </Stack>
        {fetchPatientOrderState === FetchState.LOADING && (
          <Box mt={8}>
            <ListLoading />
          </Box>
        )}
        {(fetchPatientOrderState === FetchState.SUCCESS &&
          patientOrderList.length == 0) ||
          (isBlurred && (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img src={IMAGES.EmptyBox} width={"60%"} height={"60%"} />
              <Typography fontWeight={660} variant="body1">
                No orders yet
              </Typography>
            </Stack>
          ))}
        {fetchPatientOrderState === FetchState.SUCCESS &&
          patientOrderList.length > 0 && (
            <Stack gap={3} mt={8} width="90%">
              {patientOrderList.length > 0 &&
                !isBlurred &&
                patientOrderList.map((order, index) => {
                  return (
                    <Card
                      sx={{
                        border: "1.5px solid #d7d9d7",
                        borderRadius: "12px",
                        maxHeight: "40vh",
                      }}
                      variant="elevation"
                    >
                      <CardHeader
                        avatar={<Avatar src={IMAGES.DeliveryBoxImage}></Avatar>}
                        action={
                          <Button
                            onClick={() => handleViewOrderDetails(order.id)}
                          >
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <VisibilityOutlinedIcon
                                color={"secondary"}
                                fontSize="small"
                              />
                              <Typography
                                fontSize={"10px"}
                                fontWeight={660}
                                color={"secondary"}
                                textTransform={"none"}
                              >
                                View Details
                              </Typography>
                            </Stack>
                          </Button>
                        }
                        title={
                          <Typography
                            variant="caption"
                            fontWeight={660}
                            color={"secondary"}
                          >
                            Order Id: #{order.id}
                          </Typography>
                        }
                        subheader={
                          <Stack>
                            <Typography variant="caption">
                              Order Type: {order.order_type}
                            </Typography>
                            <Typography variant="caption">
                              Total Amount:{" "}
                              {parseFloat(order.total_amount).toFixed(2)}
                            </Typography>
                            <Typography variant="caption">
                              Ordered Date:{" "}
                              {moment(order.order_date).format("LL")}
                            </Typography>
                          </Stack>
                        }
                      />
                    </Card>
                  );
                })}
            </Stack>
          )}
      </Box>

      {openOrderDetailsModal && (
        <OrderDetailsModal
          openModal={openOrderDetailsModal}
          handleClose={handleCloseOrderDetails}
        />
      )}
    </>
  );
};
export default Orders;
