import secureLocalStorage from "react-secure-storage";
import { ACCESS_TIME_KEY, AUTH_TOKEN_KEY, AUTH_USER_KEY, PATIENT_TOKEN_KEY, PSP_AUTH_TOKEN_KEY, USER_TOKEN_KEY } from "../../base";
import { User } from "../../domain/models/user";
import { LoggedInUser } from "../../domain/usages/logged-in-user";
import { JsonStorage } from "../protocols/storage/json-storage";
import { LoginUser } from "../../domain/models/user-login-type";

export class LocalLoggedInUser implements LoggedInUser {
  userKey: string = AUTH_USER_KEY;
  tokenKey: string = AUTH_TOKEN_KEY;
  pspTokenKey: string = PSP_AUTH_TOKEN_KEY;
  accessTimeKey:string= ACCESS_TIME_KEY;
  patientTokenKey: string= PATIENT_TOKEN_KEY;
  userTokenKey: string= USER_TOKEN_KEY;

  constructor(private readonly jsonStorage: JsonStorage ) {}

  setUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  getUser(): User | null {
    let user = this.jsonStorage.get(this.userKey);
    if (user && user !== "") return JSON.parse(this.decrypt(user));
    return null;
  }

  getToken(): any {
    const data = secureLocalStorage.getItem(
      this.tokenKey
    );
    if (!data) { this.logout(); return ""}
    return data;
 
  }

  getUserDetails(): any  {
    const data = secureLocalStorage.getItem(
      this.userTokenKey
    );
    if (!data) {this.logout(); return ""}
    return data;

  }

  getAppAccessTime(): any  {
    const data = secureLocalStorage.getItem(
      this.accessTimeKey
    );
    if (!data) {this.logout(); return ""}
    return data;

  }
  getPatientToken(): any{
    const data = secureLocalStorage.getItem(
      this.patientTokenKey
    );
    if (!data) { this.logout(); return ""}
    return data;



  }

  getPSPToken(): any {
    const data = secureLocalStorage.getItem(
      this.pspTokenKey
    );
    if (!data) { this.logout(); return ""}
    return data;

  
  }

  setToken(token: string): void {
    secureLocalStorage.setItem(this.tokenKey, token);
  }

  setUserDetails(data: LoginUser): void {
    secureLocalStorage.setItem(this.userTokenKey, data);
  }


  setPSPToken(token: string): void {
    secureLocalStorage.setItem(this.pspTokenKey, token);
  }

  setAppAccessTime(time: string): void {
    secureLocalStorage.setItem(this.accessTimeKey, time);
  }

  setPatientToken(token: string): void {
    secureLocalStorage.setItem(this.patientTokenKey, token);
  }

  logout(): void {
    secureLocalStorage.clear();
      
    this.jsonStorage.remove(this.userKey);
    this.jsonStorage.remove(this.tokenKey);
   
  }

  resetUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(value: string) {
    return atob(value);
  }
}
