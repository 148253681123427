import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { CountriesResponse, Country } from "../../../domain/models/countires";
import { RemoteFetchCountries } from "../../../data/usecases/remote-fetch-countries-list";

interface FetchCountriesListSliceInterface {
  fetchCountriesList: Function;
  fetchCountriesListLoading: FetchState;
  countriesList: Country[];
}

const initialStates = {
  fetchCountriesListLoading: FetchState.DEFAULT,
  countriesList: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchCountriesListSlice: StateCreator<
  FetchCountriesListSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchCountriesList: async (captcha?: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha ?? "" });
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ?? "" });
    const remoteFetchCountriesList = new RemoteFetchCountries(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_COUNTRIES}`,
      axiosHttpClient
    );
    set(() => ({ fetchCountriesListLoading: FetchState.LOADING }));
    let result: CountriesResponse = await remoteFetchCountriesList.fetch({});
    if (result.success) {
      set(() => ({
        fetchCountriesListLoading: FetchState.SUCCESS,
        countriesList: result.countries,
      }));
    } else {
      set(() => ({ fetchCountriesListLoading: FetchState.ERROR }));
    }
  },
});
