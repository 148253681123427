import StaticProgram from "./StaticProgram";
import EditPrograms from "./EditPrograms";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import OrderPlacingTabs from "./OrderPlacingTabs";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

import { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";

const ProgramDetails: React.FC = () => {
  const { value, fetchMasterDropdownData } = useProgramDetailStore();
  const { selectedCard, fetchCountriesList } = useEnrollmentMainStore();
  const { fetchProgramOptions } = useProgramDetailStore();
  const [isProgramOptionsFetched, setIsProgramOptionsFetched] = useState(false);
  const {
    captchaToken: fetchMasterDropdownDataToken,
    executeRecaptcha: executeFetchMasterDropdownDataRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchMasterDropdownData");
  const {
    captchaToken: fetchProgramOptionsToken,
    executeRecaptcha: executeFetchProgramOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramOptions");

  useEffect(() => {
    if (selectedCard.program_id) {
      executeFetchProgramOptionsRecaptcha();
      executeFetchMasterDropdownDataRecaptcha();
      fetchCountriesList();
    }
  }, [selectedCard.program_id, value]);

  useEffect(() => {
    setIsProgramOptionsFetched(false);
    if (fetchProgramOptionsToken) {
      let params = {
        program_id: selectedCard.program_id,
      };

      fetchProgramOptions(fetchProgramOptionsToken, params).then(() => {
        setIsProgramOptionsFetched(true);
      });
    }
  }, [fetchProgramOptionsToken, selectedCard.program_id]);

  useEffect(() => {
    if (fetchMasterDropdownDataToken) {
      fetchMasterDropdownData(
        selectedCard.program_id,
        fetchMasterDropdownDataToken
      );
    }
  }, [fetchMasterDropdownDataToken]);

  return (
    <>
      {value == 0 &&
        (isProgramOptionsFetched ? (
          <StaticProgram />
        ) : (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            height={"60vh"}
          >
            <CircularProgress />
          </Stack>
        ))}
      {value == 1 && <OrderPlacingTabs />}
      {value == 2 && <EditPrograms />}
    </>
  );
};
export default ProgramDetails;
