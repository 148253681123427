import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import IMAGES from "../../../assets/images";
import HeaderActions from "./HeaderActions";

const Header: React.FC = () => {
  return (
    <Stack
      padding={1}
      direction="row"
      justifyContent={"space-between"}
      border={"1px solid rgba(221, 221, 221, 0.5)"}
    >
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Avatar src={IMAGES.CallCenter}></Avatar>
        <Typography variant="h6">Patient Support Program</Typography>
      </Stack>
      <Stack alignItems="center">
        <HeaderActions />
      </Stack>
    </Stack>
  );
};
export default Header;
