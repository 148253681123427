import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import FilterComponent from "../patient-information/FilterComponent";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const style = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "35px",
  },
  display: "flex",
  justifyContent: "center",
};

const EnrollmentFilters: React.FC = () => {
  const { filter, handleAppliedFilters, handleFilterOpen, resetFilter } =
    useEnrollmentMainStore();

  useEffect(() => {
    resetFilter();
  }, []);

  const handleSearch = (e: any) => {
    filter.q = e.target.value;
    handleAppliedFilters(filter);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);
  return (
    <Grid
      container
      sx={{ paddingLeft: 1, paddingTop: 1.5 }}
      spacing={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={10} alignItems={"center"}>
        <TextField
          autoComplete="new-password"
          onChange={debouncedOnChange}
          sx={style}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => handleFilterOpen()}>
          <FilterAltIcon />
        </IconButton>
      </Grid>
      <FilterComponent />
    </Grid>
  );
};

export default EnrollmentFilters;
