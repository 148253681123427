import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import { PatientDocument } from "../../../../domain/models/patient-document";
import { RemoteFetchPatientDocuments } from "../../../../data/usecases/remote-fetch-patient-documents";
import { FetchPatientDocuments } from "../../../../domain/usages/fetch-patient-documents";
import { FetchState } from "../../../../domain/models/fetch-state-type";

interface FetchPatientDocumentSliceType {
  fetchPatientDocs: Function;
  handleRefreshUrl: Function;
  patientDocuments: PatientDocument[];
  loadingPatientDocuments: FetchState;
  currentPage: number;
  lastPage: number;
  totalDocCount: number;
  reloadPage: Function;
}

const initialStates = {
  currentPage: 1,
  lastPage: 1,
  patientDocuments: [],
  totalDocCount: 0,
  loadingPatientDocuments: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchPatientDocumentSlice: StateCreator<
  FetchPatientDocumentSliceType
> = (set, get) => ({
  ...initialStates,
  reloadPage: () => {},
  handleRefreshUrl: () => {
    set(() => ({ patientDocuments: [] }));
  },
  fetchPatientDocs: async (
    enrolmentId: string,
    group: string,
    type: string,
    page: number,
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ?? "" });
    // axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha ?? "" });

    const remoteFetchPatientDocuments: FetchPatientDocuments =
      new RemoteFetchPatientDocuments(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_DOCUMENTS}`,
        axiosHttpClient
      );
    let params = {
      group: group,
      type: type,
      page: page,
      length: 5,
    };

    try {
      set(() => ({ loadingPatientDocuments: FetchState.LOADING }));

      let result = await remoteFetchPatientDocuments.fetch(enrolmentId, params);
      if (result.success) {
        set(() => ({
          totalDocCount: result.documents.total,
          currentPage: result.documents.current_page,
          lastPage: result.documents.last_page,
          loadingPatientDocuments: FetchState.SUCCESS,
        }));

        set(() => ({ patientDocuments: result.documents.data }));
      }
    } catch (e) {
      set(() => ({ loadingPatientDocuments: FetchState.ERROR }));
    }
  },
});
