import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { FetchProducts } from "../../domain/usages/fetch-products";
import { FetchPatientDocuments } from "../../domain/usages/fetch-patient-documents";

export class RemoteFetchPatientDocuments implements FetchPatientDocuments {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(enrolmentId:string, params?: FetchPatientDocuments.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id",enrolmentId),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
      authHeadersViaCaptcha: false
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
