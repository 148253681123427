import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

type Props = {
  openModal: boolean;
  handleCloseModal: Function;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LockModal: React.FC<Props> = ({ openModal, handleCloseModal }) => {
  const { lockScreen, isBlurred } = useEnrollmentMainStore();

  useEffect(() => {
    if (isBlurred) {
      handleCloseModal();
    }
  }, [isBlurred]);

  return (
    <>
      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Box sx={style}>
          <Typography>🔑 Are you sure to lock screen?</Typography>
          <Stack flexDirection={"row"} justifyContent="space-around">
            <Button
              variant="contained"
              onClick={() => {
                handleCloseModal();
              }}
            >
              <Typography textTransform={"none"}>Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                lockScreen();
              }}
            >
              <Typography textTransform={"none"}>Confirm</Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default LockModal;
