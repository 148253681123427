import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Box,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import ImageComponent from "../../ImageComponent";
import AddDocumentModal from "./AddDocumentModal";
import UpdateDocumentModal from "./UpdateDocumentModal";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";
import "../../../../components/patient-information/OncologyPageStyles.css";
import { DocumentGroupType } from "../../../../../domain/models/master";
import IMAGES from "../../../../../assets/images";
import GaDialogBox from "../../../GaDialogBox";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const DocumentCard: React.FC<{
  document: any;
  isBlurred: boolean;
  onUpdate: Function;
  handleRemoveDocument: Function;
}> = ({ document, isBlurred, onUpdate, handleRemoveDocument }) => (
  <Card variant="outlined">
    <CardHeader
      title={
        <Typography variant="body1" fontWeight={600}>
          {document.document_type}
        </Typography>
      }
    />
    <ImageComponent uuid={document.uuid} />
    <Stack direction="row" spacing={1} marginLeft={1}>
      <Typography variant="subtitle2" fontWeight="bold">
        Group:
      </Typography>
      <Typography variant="subtitle2">{document.document_group}</Typography>
    </Stack>

    {!isBlurred && (
      <Stack direction={"row"} margin={2} gap={2} justifyContent={"end"}>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => handleRemoveDocument(document)}
        >
          <Typography textTransform={"none"} variant="subtitle2">
            Remove
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => onUpdate()}
        >
          <Typography textTransform={"none"} variant="subtitle2">
            Update
          </Typography>
        </Button>
      </Stack>
    )}
  </Card>
);

const ProgramDocs: React.FC = () => {
  const { selectedCard, isBlurred } = useEnrollmentMainStore();
  const {
    fetchProgramDocuments,
    handleAddNewDocumentModal,
    handleUpdateDocumentModal,
    handleRefreshUrl,
    showAddNewDocumentModal,
    showUpdateDocumentModal,
    fetchPatientDocs,
    currentPage,
    lastPage,
    totalDocCount,
    programDocuments,
    patientDocuments,
    loadingPatientDocuments,
    updatePatientDocumentStatus,
    updatePatientDocumentStatusLoading,
  } = useDocumentStore();

  const [documentGroup, setDocumentGroup] = useState("");
  const [documentType, setDocumentType] = useState<DocumentGroupType[]>([]);
  const [document, setDocument] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const {
    captchaToken: fetchProgramDocumentsToken,
    executeRecaptcha: executeFetchProgramDocumentsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramDocuments");

  useEffect(() => {
    executeFetchProgramDocumentsRecaptcha();
    handleRefreshUrl();
  }, [handleRefreshUrl]);

  useEffect(() => {
    if (fetchProgramDocumentsToken) {
      fetchProgramDocuments(
        selectedCard.program.id,
        fetchProgramDocumentsToken
      );
    }
  }, [
    fetchProgramDocumentsToken,
    fetchProgramDocuments,
    selectedCard.program.id,
  ]);

  const handleRemoveDocument = (document: any) => {
    setSelectedDocument(document);
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    if (selectedCard.id) {
      fetchPatientDocs(selectedCard.id, "", "", 1);
    }
  }, [selectedCard.id]);

  const handleSelectDocumentGroup = (e: any) => {
    setDocumentGroup(e.target.value);
    if (programDocuments.length > 0) {
      for (let i = 0; i < programDocuments.length; i++) {
        if (programDocuments[i].code == e.target.value)
          setDocumentType(programDocuments[i].document_types);
      }
    } else {
      setDocumentType([]);
    }
  };

  const handleSelectDocument = (e: any) => {
    setDocument(e.target.value);
  };

  const hasMoreData = () => {
    return loadingPatientDocuments == FetchState.SUCCESS
      ? currentPage < lastPage
      : false;
  };

  return (
    <Stack overflow="scroll" height="70vh">
      {!isBlurred && (
        <Stack
          justifyContent="end"
          bgcolor="white"
          position="fixed"
          zIndex={"9999"}
          width="41%"
          gap={2}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => handleAddNewDocumentModal(true)}
          >
            <Typography variant="subtitle2" fontWeight="bold">
              Add New Document
            </Typography>
          </Button>
          <Stack
            direction="row"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <TextField
              select
              fullWidth
              size="small"
              value={documentGroup}
              onChange={handleSelectDocumentGroup}
              label="Select Document Group"
            >
              {programDocuments.length > 0 &&
                programDocuments.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              size="small"
              disabled={documentGroup == ""}
              fullWidth
              value={document}
              onChange={handleSelectDocument}
              label="Select Document Type"
            >
              {documentType.map((option) => (
                <MenuItem key={option.id} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                fetchPatientDocs(selectedCard.id, documentGroup, document, 1);
              }}
              sx={{ width: "25%", height: "25%" }}
            >
              <Typography textTransform={"none"} variant="subtitle2">
                Apply
              </Typography>
            </Button>
          </Stack>
        </Stack>
      )}
      {(loadingPatientDocuments == FetchState.LOADING ||
        updatePatientDocumentStatusLoading) && (
        <Stack
          mt={20}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Stack>
      )}

      {loadingPatientDocuments == FetchState.SUCCESS &&
        patientDocuments.length == 0 && (
          <Stack alignItems={"center"} justifyContent={"center"} mt={20}>
            <img src={IMAGES.EmptyRecords} width={"200px"} />
            <Typography fontWeight={660} variant="body1" textAlign={"center"}>
              We couldn't find any records matching your search criteria.
            </Typography>
          </Stack>
        )}

      <Box
        id="document_id"
        height={isBlurred ? "60vh" : "54vh"}
        position={"fixed"}
        width={"45%"}
        overflow="auto"
        mt={isBlurred ? 2 : 11}
      >
        {!isBlurred &&
          loadingPatientDocuments == FetchState.SUCCESS &&
          patientDocuments.length > 0 && (
            <Stack
              justifyContent={currentPage != 1 ? "space-between" : "end"}
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
                mb: 2,
                padding: "0 20px", // Adds padding on the sides
              }}
            >
              {currentPage !== 1 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    fetchPatientDocs(
                      selectedCard.id,
                      documentGroup,
                      document,
                      currentPage - 1
                    )
                  }
                  startIcon={<ArrowBack />}
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    padding: "6px 16px",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <Typography variant="caption">Previous Page</Typography>
                </Button>
              )}

              {currentPage !== lastPage && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    fetchPatientDocs(
                      selectedCard.id,
                      documentGroup,
                      document,
                      currentPage + 1
                    )
                  }
                  endIcon={<ArrowForward />}
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    padding: "6px 16px",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <Typography variant="caption">Next Page</Typography>
                </Button>
              )}
            </Stack>
          )}
        {loadingPatientDocuments == FetchState.SUCCESS &&
          patientDocuments.length > 0 && (
            <Grid container spacing={0.5} direction="row">
              {patientDocuments.map((document, index) => (
                <Grid item xs={4} key={index}>
                  <DocumentCard
                    document={document}
                    isBlurred={isBlurred}
                    handleRemoveDocument={handleRemoveDocument}
                    onUpdate={handleUpdateDocumentModal}
                  />
                </Grid>
              ))}
            </Grid>
          )}
      </Box>

      {showAddNewDocumentModal && <AddDocumentModal />}
      {showUpdateDocumentModal && <UpdateDocumentModal />}
      {selectedDocument && openConfirmModal && (
        <GaDialogBox
          title={`Are you sure you want to deactivate document: `}
          actionItem={
            <Stack>
              <Stack
                direction={"row"}
                margin={2}
                gap={2}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => setOpenConfirmModal(false)}
                >
                  <Typography textTransform={"none"} variant="subtitle2">
                    Close
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setOpenConfirmModal(false);
                    updatePatientDocumentStatus(
                      "inactive",
                      selectedCard.id,
                      selectedDocument.id
                    );
                  }}
                >
                  <Typography textTransform={"none"} variant="subtitle2">
                    Confirm
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          }
          handleClose={() => setOpenConfirmModal(false)}
          openDialog={openConfirmModal}
          content={
            <Stack>
              <Typography variant="subtitle2" fontWeight={700}>
                Group: {selectedDocument.document_group}
              </Typography>
              <Typography variant="subtitle2" fontWeight={700}>
                Type: {selectedDocument.document_type}
              </Typography>
            </Stack>
          }
        />
      )}
    </Stack>
  );
};

export default ProgramDocs;
