import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { RemoteVerifyMobile } from "../../../data/usecases/remote-verify-mobile";
import { VerifyMobile } from "../../../domain/usages/verify-mobile";

interface VerifyMobileSliceType {
  verifyMobile: Function;
  verifyMobileState: FetchState;
  mobileVerificationStatus: boolean;
}

const initialStates = {
  verifyMobileState: FetchState.DEFAULT,
  mobileVerificationStatus: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useVerifyMobileSlice: StateCreator<VerifyMobileSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  verifyMobile: async (mobile: string, captcha?: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha ?? "" });
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ?? "" });
    const remoteVerifyMobile: VerifyMobile = new RemoteVerifyMobile(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.VERIFY_MOBILE}`,
      axiosHttpClient
    );

    set(() => ({ verifyMobileState: FetchState.LOADING }));
    let result = await remoteVerifyMobile.verify({ mobile });
    if (result.success) {
      set(() => ({
        mobileVerificationStatus: result.message,
        verifyMobileState: FetchState.SUCCESS,
      }));
    } else {
      set(() => ({ verifyMobileState: FetchState.ERROR }));
    }
  },
});
