import { Box, Button, Stack, Typography } from "@mui/material";
import AppointmentCard from "./AppointmentCard";
import CreateAppointment from "./CreateAppointment";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import ListLoading from "../../../Loading/ListLoading";
import IMAGES from "../../../../../assets/images";
import GaButton from "../../../GaButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

const AppointmentModule: React.FC = () => {
  const {
    openCreateAppointment,
    fetchAppointments,
    appointmentData,
    fetchAppointmentsLoading,
    appointmentFilter,
    page,
    totalAppointments,
    handleAddAppointmentClick,
    fetchAppointmentDocumentTypes,
  } = useAppointmentDetailsStore();
  const { isBlurred } = useEnrollmentMainStore();

  useEffect(() => {
    fetchAppointments(1, false, {
      requester_uuid:
        useEnrollmentMainStore.getState().selectedCard.patient_uuid,
    });
  }, []);

  const hasMoreData = () => {
    return appointmentData.length < totalAppointments;
  };

  return (
    <Box id="appointment_list" overflow="scroll" height="70vh">
      {openCreateAppointment ? (
        <CreateAppointment />
      ) : (
        <>
          <Stack>
            {!isBlurred && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleAddAppointmentClick(openCreateAppointment);
                }}
                sx={{ marginBottom: 1 }}
                startIcon={<AddCircleRoundedIcon />}
              >
                <Typography textTransform={"none"}>Book Appointment</Typography>
              </Button>
            )}
          </Stack>

          {fetchAppointmentsLoading === FetchState.LOADING && <ListLoading />}
          {fetchAppointmentsLoading === FetchState.SUCCESS &&
          appointmentData.length > 0 &&
          !isBlurred ? (
            <InfiniteScroll
              scrollableTarget="appointment_list"
              dataLength={appointmentData.length}
              next={() => {
                fetchAppointments(page + 1, true, appointmentFilter);
              }}
              hasMore={hasMoreData()}
              loader={<ListLoading />}
              endMessage={
                <Typography display={"flex"} justifyContent="center">
                  <b>End...</b>
                </Typography>
              }
            >
              {appointmentData.map((appointment, index) => (
                <AppointmentCard key={index} appointment={appointment} />
              ))}
            </InfiniteScroll>
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img src={IMAGES.EmptyBox} width={"60%"} height={"60%"} />
              <Typography fontWeight={660} variant="body1">
                No Appointments yet
              </Typography>
            </Stack>
          )}
        </>
      )}
      {fetchAppointmentsLoading === FetchState.ERROR && (
        <Typography textAlign={"center"} color="red">
          Something went wrong
        </Typography>
      )}
    </Box>
  );
};

export default AppointmentModule;
