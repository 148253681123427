import { create } from "zustand";
import { useCreateEnrollmentSlice } from "./createEnrollmentSlice";
import { useFetchEnrollmentSlice } from "./fetchEnrollmentSlice";
import { useFilterEnrollmentSlice } from "./filterEnrollmentSlice";
import { useCreateEnrollmentByPatientSlice } from "./createEnrollmentByPatientSlice";
import { useSendConsentSMSSlice } from "./sendConsentSMSSlice";
import { useBlurTextSlice } from "./blurTextSlice";
import { useFetchCountriesListSlice } from "./fetchCountriesListSlice";
import { useVerifyMobileSlice } from "./verifyMobileSlice";

interface EnrollmentStoreInterface
  extends ReturnType<typeof useFetchEnrollmentSlice>,
    ReturnType<typeof useCreateEnrollmentSlice>,
    ReturnType<typeof useFilterEnrollmentSlice>,
    ReturnType<typeof useCreateEnrollmentByPatientSlice>,
    ReturnType<typeof useSendConsentSMSSlice>,
    ReturnType<typeof useBlurTextSlice>,
    ReturnType<typeof useFetchCountriesListSlice>,
    ReturnType<typeof useVerifyMobileSlice> {}

export const useEnrollmentMainStore = create<EnrollmentStoreInterface>()(
  (...enrollment) => ({
    ...useFetchEnrollmentSlice(...enrollment),
    ...useCreateEnrollmentSlice(...enrollment),
    ...useFilterEnrollmentSlice(...enrollment),
    ...useCreateEnrollmentByPatientSlice(...enrollment),
    ...useSendConsentSMSSlice(...enrollment),
    ...useBlurTextSlice(...enrollment),
    ...useFetchCountriesListSlice(...enrollment),
    ...useVerifyMobileSlice(...enrollment),
  })
);
