import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import { RemoteUpdatePatientDocumentStatus } from "../../../../data/usecases/remote-update-patient-document-status";
import { UpdatePatientDocumentStatus } from "../../../../domain/usages/update-patient-document-status";
import Swal from "sweetalert2";
import { useDocumentStore } from "./documentStore";

interface FetchPatientDocumentSliceType {
  updatePatientDocumentStatus: Function;
  updatePatientDocumentStatusLoading: boolean
}

const initialStates = {
    updatePatientDocumentStatusLoading: false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdatePatientDocumentStatusSlice: StateCreator<
  FetchPatientDocumentSliceType
> = (set, get) => ({
  ...initialStates,

  updatePatientDocumentStatus: async (status: string, enrolmentId:string, documentId: string) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token ?? "" });


    const remoteUpdatePatientDocumentStatus: UpdatePatientDocumentStatus =
      new RemoteUpdatePatientDocumentStatus(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.UPDATE_DOCUMENT_STATUS}`,
        axiosHttpClient
      );
    let params = {
      status: status,
    };

    let result = await remoteUpdatePatientDocumentStatus.update(enrolmentId, documentId, params);
    if (result.success) {
      useDocumentStore
              .getState()
              .fetchPatientDocs(enrolmentId, "", "", 1);
      Swal.fire({
        title: result.message,
        timer: 3000,
        icon: "success",
      });
      
    } else {
      Swal.fire({
        title: "Remove document failed",
        icon: "error",
      });
    }
  },
});
